import { registerGtmDataLayer } from '../../../../../Features/Blocks/Video/gtmDataLayerService';

export default async function (body, url) {
  const response = await fetch(url, {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => {
      status = res.status
      if (res.ok) {
        return res.json()
      } else {
        throw new Error("Could not fetch salesforce id.")
      }
    })
    .then((json) => {
      return json
    })
    .catch((err) => {
      console.error(err)
      return err
    })

  if (response && response.name && response.email && response.hash) {
      let dataLayer = {
          'event': 'track-event',
          'event_source': 'kontaktformular',
          'eventCategory': 'hashing',
          'eventAction': 'hashing',
          'eventLabel': 'standalone',
          'name': response.name,
          'lastname': response.lastname,
          'email': response.email,
          'phonenumber': response.phonenumber,
          'hfnem': response.hash
      };

      registerGtmDataLayer(dataLayer);
    }
  return {
      data: response,
      status: status,
  }
}
